var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"43d882540cfc537fc80b233c84be5202bb43f070"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const isProd = process.env.NEXT_PUBLIC_ENV === 'production';
const isDeployPreview = process.env.NEXT_PUBLIC_ENV === 'preview';
const isBranchDeploy = process.env.NEXT_PUBLIC_ENV === 'branch';

if (isProd || isDeployPreview || isBranchDeploy) {
  console.log('Sentry enabled');
  console.log('isProd', isProd);
  console.log('isDeployPreview', isDeployPreview);
  console.log('isBranchDeploy', isBranchDeploy);

  Sentry.init({
    dsn: 'https://3de31aa39dcae33e8caa2267dab9bf98@o4506186688299008.ingest.sentry.io/4506195208372224',

    environment: process.env.NEXT_PUBLIC_ENV || 'development',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      new Sentry.Replay({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
}
